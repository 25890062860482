import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import loadable from '@loadable/component';
import { Carousel } from "react-bootstrap";

import categoryBannerImg from "../../assets/images/assad_arcade_category/assad_arcade_banner.png";
import gameImg1 from "../../assets/images/games/samplegameImg.png";
import { getCustomCategoryDetailAction } from "../../redux/actions/categoryActions";
import { getCloudFrontSourcePath } from "../../utils/functions";
import CustomCountdown from "../../components/CustomCategory/CustomCountdown";
import { useTranslation } from "react-i18next";
import { ArcadeCarouselLArrowClickAction, ArcadeCarouselRArrowClickAction } from "../../redux/actions/assad_arcade/AssadgoogleAnalyticsActions";
import { getNextMonday, getTomorrow } from '../../utils/time_utils';
import CustomCategoryPopup from "../../components/CustomCategory/CustomCategoryPopup";
import ic_info from "../../assets/images/info_button.png";
import lionCoinImage from '../../assets/images/confetti/reward_coin.png';

const Loader = loadable(() => import('../../components/UI/Loader'));
const CustomCategoryGameCard = loadable(() => import('../../components/UI/CustomCategoryGameCard'));

const CustomCategoryGamesListing = () => {
	const { t, i18n } = useTranslation();
	const CategoryState = useSelector((state) => state.Category);
	const GameState = useSelector((state) => state.Game);
	const dispatch = useDispatch();
	const [showInfoPopup, setShowInfoPopup] = useState(false);

	const { authTokenAssad, categoryId, language } = useParams();
	const { custom_category_loader, custom_category_data } = CategoryState;

	if (!localStorage.getItem("token") && authTokenAssad) {
		localStorage.setItem("token", authTokenAssad);
		localStorage.setItem("isFromCustomCategory", true);
	}

	useEffect(() => {
		dispatch(getCustomCategoryDetailAction({ cat_id: categoryId }));
	}, [dispatch, categoryId]);

	// Set Language
	useEffect(() => {
		if (language) {
			i18n.changeLanguage(language);
		}
	}, [language, i18n]);

	useEffect(() => {
		if (GameState.loading) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
		// Clean up to reset overflow when the component unmounts
		return () => {
			document.body.style.overflow = 'auto';
		};
	}, [GameState.loading]);

	// Send message to parent when the component mounts
	useEffect(() => {
		window.parent.postMessage({ type: 'akedo', payload: 'assad_arcade_loaded' }, '*');
	}, []);

	const gameData = custom_category_data?.apps || [];
	const cloudFrontSourcePath = getCloudFrontSourcePath();

	const handleSlide = (e, direction) => {
		if (direction === "end") {
			dispatch(ArcadeCarouselLArrowClickAction());
		} else if (direction === "start") {
			dispatch(ArcadeCarouselRArrowClickAction());
		}
	};

	const hideInfo = () => {
		setShowInfoPopup(false);
	};

	const showInfo = () => {
		setShowInfoPopup(true);
	};

	const infoMessageData = {
		status: 0,
		readDate: null,
		_id: "66a9061c7144339c1ac7b284",
		createdDate: "2024-07-30T15:26:20.873Z",
		user: "66a25c056b94ced564deaac0",
		gameId: "assad.game",
		type: "friend_referral",
		title: t('tournaments.info_title'),
		text: t('tournaments.info_text'),
		buttons: [
			{
				text: "OK",
				action: "close",
			},
			
		],
		action: "",
		image: null,
		__v: 0,
	};

	// Where you use translations
	const translatedText = t('tournaments.top_10_reward', 'Default text if missing');
	console.log('Translation attempt:', {
	key: 'tournaments.top_10_reward',
	result: translatedText,
	available: i18n.exists('tournaments.top_10_reward')
	});

	return (
		<>
			{custom_category_loader ? (
				<Loader isTranserent={true} />
			) : (
				<>
					{/* Display loader if the game is loading */}
					{GameState.loading && (
						<div className="loader-overlay">
							<Loader isTranserent={true}/>
						</div>
					)}
					<section className="categoryBanner relative bg-gradient-to-b from-[#21024D] to-[#030307] flex items-center">
						<img src={categoryBannerImg} alt="category banner" width={375} height={427} className="w-full h-[350px] object-cover" />
						
						{!showInfoPopup && (
							<button
								className="absolute top-4 right-4 p-2 rounded-full"
								onClick={showInfo}								
							>
								<img src={ic_info} alt="Info Button" className="w-6 h-6" />
							</button>
						)}

						<div className="w-[calc(50%+75px)] absolute top-1/2 z-10 -translate-y-1/2 right-3 overflow-hidden">
							<Carousel
								onSlide={handleSlide}
								wrap={false}
								pause={true}
								interval={null}
								indicators={false}
								controls={true}
								className="font-skia countdown-carousel"
								nextIcon={
									<span style={{ position: "absolute", top: "-52px", right: "30px", zIndex: "1" }}>
										<svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none" className="rotate-180">
											<path d="M8.50015 1.07785L1.98015 7.59785C1.21015 8.36785 1.21015 9.62785 1.98015 10.3978L8.50015 16.9178" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
									</span>
								}
								prevIcon={
									<span style={{ position: "absolute", top: "-52px", left: "30px", zIndex: "1" }}>
										<svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
											<path d="M8.50015 1.07785L1.98015 7.59785C1.21015 8.36785 1.21015 9.62785 1.98015 10.3978L8.50015 16.9178" stroke="white" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
									</span>
								}
							>
								<Carousel.Item>
									<h2 className="text-white font-bold text-center mb-3">{t('tournaments.weekly_title')}:</h2>
									<CustomCountdown type="weekly" date={getNextMonday()} />

									<h2
									style={{ marginTop: '10px', fontSize: '13px' }}
									className="text-white font-bold text-center mb-3 flex items-center justify-center"
									>
									{t('tournaments.top_10_reward')} 
									<img src={lionCoinImage} alt="Lion Coin" className="w-7 h-7 mx-0" />
									100k 
									</h2>

									
								</Carousel.Item>
								<Carousel.Item>
									<h2 className="text-white font-bold text-center mb-3">{t('tournaments.daily_title')}:</h2>
									<CustomCountdown type="daily" date={getTomorrow()} />

									<h2
									style={{ marginTop: '10px', fontSize: '13px' }}
									className="text-white font-bold text-center mb-3 flex items-center justify-center"
									>
									{t('tournaments.top_10_reward')} 
									<img src={lionCoinImage} alt="Lion Coin" className="w-7 h-7 mx-0" />
									10k 
									</h2>
									
								</Carousel.Item>								
							</Carousel>
						</div>
						
					</section>
					<section className="viewAllGame -mt-[60px] relative z-10">
						<div className="container customCategoryGameCard" style={{ padding: '10px 20px' }}>
							<div className="gameCards flex-col mt-0 mx-0">
								{gameData?.map((game, index) => (
									<CustomCategoryGameCard
										keyIndex={index}
										img={game?.icon ? `${cloudFrontSourcePath}${game?.icon}` : gameImg1}
										game={game}
										key={`category_section_${index}`}
										subtitle={custom_category_data?.name}
										isFromCustomCategory={true}
										leaderBoard={true}
										currRoute="category_game_listing"
									/>
								))}
							</div>
						</div>
					</section>
				</>
			)}
			{showInfoPopup && (
				<CustomCategoryPopup isModalOpen={showInfoPopup} closeModal={hideInfo} modalData={infoMessageData} />
			)}
		</>
	);
};

export default CustomCategoryGamesListing;
