import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import loadable from "@loadable/component";
import back_buttonImg from '../../assets/images/back_button.png';
import green_logoImg from '../../assets/images/green_logo.png';
import back_buttonAssadImg from "../../assets/images/back_button_assad.png";
import green_logoAssadImg from "../../assets/images/green_logo_assad.png";

import { checkGameParticipationAction, fetchGameAppDataAction } from "../../redux/actions/gameActions";
import { fetchBalanceAuthAction } from "../../redux/actions/authActions";

const Loader = loadable(() => import("../../components/UI/Loader"));
const GamePlayIFrameComponent = loadable(() => import("./GamePlayIFrameComponent"));

const Play = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const GameSDKState = useSelector((state) => state.GameSDK);
    const AuthState = useSelector((state) => state.Auth);
    const GameState = useSelector((state) => state.Game);

    const { initJsResponse, token: sdkToken, loading: sdkLoading } = GameSDKState;
    const { gameData } = GameState;

    const { gameId } = useParams();

    const [loading, setLoading] = useState(true);
    const [isTelegramBrowser, setIsTelegramBrowser] = useState(false);

    const [isDragging, setIsDragging] = useState(false);
    const [buttonY, setButtonY] = useState(50); // Initial Y position of the button
    const dragTimeout = useRef(null); // To track the timer for press-and-hold
    const isClickAllowed = useRef(true); // To track if click should be allowed

    const startY = useRef(null); // Store initial touch position
    const buttonRef = useRef(null);

    useEffect(() => {
        const userAgent = window.navigator.userAgent || "";
        const hasEnergyCount = localStorage.getItem('energy_count') !== null;
        const isTelegramBrowser = typeof Telegram !== "undefined" && Telegram.WebApp;
        const isAssad = (window.location.href.includes("assad") && !window.location.href.includes("ngrok"));
        console.log(userAgent, hasEnergyCount, isTelegramBrowser, isAssad);
        if (isAssad || hasEnergyCount || isTelegramBrowser || userAgent.toLowerCase().includes("telegram")) {
            setIsTelegramBrowser(true);
        }
    }, []);

    useEffect(() => {
        const setupGame = async () => {
            if (gameData && Object.keys(gameData).length > 0) {
                setLoading(false);
                return;
            }
            try {
                await new Promise((resolve) => {
                    dispatch(fetchGameAppDataAction({ app_id: gameId }, resolve));
                });
                await new Promise((resolve, reject) => {
                    const handleParticipationSuccess = resolve;
                    const handleParticipationReject = () => {
                        navigate(-1);
                        reject();
                    };
                    dispatch(checkGameParticipationAction({ app_id: gameId }, handleParticipationSuccess, handleParticipationReject));
                });
                setLoading(false);
            } catch (error) {
                console.error("Error during game setup:", error);
            }
        };

        setupGame();
    }, [dispatch, gameId, gameData, navigate]);

    useEffect(() => {
        return () => {
            if (AuthState?.token) {
                dispatch(fetchBalanceAuthAction());
            }
        };
    }, [dispatch, AuthState?.token]);

    const handleMouseDown = (e) => {
        //console.log("handleMouseDown");
        e.preventDefault();
        isClickAllowed.current = true; // Assume click is allowed initially
        dragTimeout.current = setTimeout(() => {
            setIsDragging(true); // Enable dragging after 1 second of press
        }, 300);
    };

    const handleMouseUp = (e) => {
        //console.log("handleMouseUp");
        e.preventDefault();
        clearTimeout(dragTimeout.current); // Clear the timer
        setIsDragging(false); // Stop dragging
    };

    const handleMouseMove = (e) => {
        //console.log("handleMouseMove: ", isDragging);
        if (isDragging) {
            isClickAllowed.current = false; // Dragging invalidates click
            const newY = e.clientY - buttonRef.current.offsetHeight / 2;
            setButtonY(newY > 0 ? newY : 0);
        }
    };

    const handleTouchStart = (e) => {
        //console.log("handleTouchStart");
        e.preventDefault();
        startY.current = e.touches[0].clientY; // Store initial Y position
        isClickAllowed.current = true; // Assume click is allowed initially
        dragTimeout.current = setTimeout(() => {
            setIsDragging(true); // Enable dragging after 1 second of press
        }, 300);
    };

    const handleTouchEnd = (e) => {
        //console.log("handleTouchEnd");
        e.preventDefault();
        clearTimeout(dragTimeout.current); // Clear the timer
        setIsDragging(false); // Stop dragging
        
        // Calculate movement difference
        const endY = e.changedTouches[0].clientY;
        const movementThreshold = 10; // Allow slight movement before treating as a tap

        if (Math.abs(endY - startY.current) < movementThreshold) {
            isClickAllowed.current = true;
            goBack();
        } else {
            setTimeout(() => {  
                isClickAllowed.current = false;  
            }, 100);
        }
    
    };

    const handleTouchMove = (e) => {
        console.log("handleTouchMove");
        if (isDragging && e.touches.length > 0) {
            isClickAllowed.current = false; // Dragging invalidates click
            const touch = e.touches[0];
            const newY = touch.clientY - buttonRef.current.offsetHeight / 2;
            setButtonY(newY > 0 ? newY : 0);
        }
    };

    const goBack = () => {
        console.log("goBack");
        if (!isDragging && isClickAllowed.current) {
            navigate(-1);
        }
    };

    if (loading || sdkLoading) {
        return <Loader isTranserent={true} />;
    }

    return (
        <div
            style={{
                width: "100%",
                height: "100vh",
                position: "relative",
            }}
            onMouseMove={handleMouseMove}
            onTouchMove={handleTouchMove}
        >
            <GamePlayIFrameComponent
                appId={gameData?.id}
                gameRoute={gameData?.game}
                token={initJsResponse?.token || sdkToken}
                authToken={AuthState?.token}
            />
            <div
                ref={buttonRef}
                className="fixed z-[999] left-0 cursor-pointer"
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
                onClick={goBack}
                style={{
                    top: `${buttonY}px`,
                    padding: "15px 10px",
                    borderRadius: "0 7px 7px 0",
                    border: `2px solid ${isTelegramBrowser ? "rgba(255, 215, 0, 0.75)" : "rgba(255, 255, 255, 0.75)"}`, // Semi-transparent gold and white
                    marginLeft: "-2px",
                    backgroundColor: isDragging ? "white" : "black", // White background in draggable mode
                    opacity: 0.7,
                    display: "flex",
                    alignItems: "center", // Center items vertically
                    flexDirection: "row", // Align items horizontally
                    gap: "4px", // Add spacing between images
                }}
            >
                <img
                    src={isTelegramBrowser ? back_buttonAssadImg : back_buttonImg}
                    alt="Back"
                    style={{
                        height: "20px", // Consistent height for both images
                    }}
                />
                <img
                    src={isTelegramBrowser ? green_logoAssadImg : green_logoImg}
                    alt="Logo"
                    style={{
                        height: "20px", // Consistent height for both images
                    }}
                />
            </div>

        </div>
    );
};

export default React.memo(Play);
