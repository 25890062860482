import React, { useCallback, useEffect, useState } from "react";
import loadable from "@loadable/component";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { useNavigate } from "react-router-dom";

import { ConnectAction, fetchBalanceAuthAction } from "../../redux/actions/authActions";
import { LoadHomDataAction } from "../../redux/actions/homeActions";
import { checkGameParticipationAction, fetchGameAppDataAction } from "../../redux/actions/gameActions";

const VideoBannerComponent = loadable(() => import("../../components/VideoBanner"));
const AllGameSectionComponent = loadable(() => import("./AllGameSectionComponent"));
const CategoriesCarousel = loadable(() => import("../categories/CategoriesCarousel"));

const Homepage = () => {
	const AuthState = useSelector((state) => state.Auth);
	const HomeState = useSelector((state) => state.Home);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);
	const [loading, setLoading] = useState(true);

	const { isWeb3Login } = AuthState;
	const isLoggedIn = Boolean(AuthState?.user && AuthState?.token);
	const { address } = useAccount();

	useEffect(() => {
		if (HomeState?.loaded && window.location?.search === "?dl=profile" && isLoggedIn) {
			dispatch(fetchBalanceAuthAction({}));
			navigate(`/`);
		}
	}, [dispatch, navigate, HomeState?.loaded, isLoggedIn]);

	
	const navigateToGame =()=>{
		const lastPlayReqGameId = localStorage.getItem("lastPlayReqGameId");

		const callbackFun =()=>{
			localStorage.removeItem("lastPlayReqGameId"); 
			navigate(`/play/${lastPlayReqGameId}`);
		}
		const handleParticipationReject = (res)=>{		
		}
		
		dispatch(checkGameParticipationAction({app_id:lastPlayReqGameId},callbackFun,handleParticipationReject)) 		
	}

	useEffect(() => {
		if (isLoggedIn) {
			const lastPlayReqGameId = localStorage.getItem("lastPlayReqGameId");
			if (lastPlayReqGameId) {				
				dispatch(fetchGameAppDataAction({app_id:lastPlayReqGameId},navigateToGame))				
			}
		}
	}, [navigate, dispatch, isLoggedIn]);

	useEffect(() => {
		const web3Token = localStorage.getItem("web3-token");
		if (address && !web3Token) {
			const payload = {
				token: "",
				wallet: address,
				wallet_type: "metamask",
				extra: {},
			};
			const successCallback = () => {
				dispatch(LoadHomDataAction());
				forceUpdate();
			};
			dispatch(ConnectAction(payload, successCallback));
		}
	}, [address, dispatch, forceUpdate]);

	const videoFilter = (data) => data?.app?.video !== "";
	const bannerVideoDataNew = HomeState?.featured?.filter(videoFilter) || [];

	useEffect(() => {
		const timer = setTimeout(() => {
			const lastPlayReqGameId = localStorage.getItem("lastPlayReqGameId");
			if (!lastPlayReqGameId) { // if lastPlayReqGameId is not set we can continue is usual and hide the loader
				setLoading(false);
			}
		}, 1000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<div>
			{loading && (
				<div
					style={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						backgroundColor: "rgba(0, 0, 0, 1)",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						color: "#fff",
						zIndex: 10,
					}}
				>
				</div>
			)}

			<div style={{ opacity: loading ? 0 : 1, transition: "opacity 0.3s" }}>
				<VideoBannerComponent isLoggedIn={isLoggedIn} featured={bannerVideoDataNew} isWeb3Login={isWeb3Login} />
				<AllGameSectionComponent />
				<CategoriesCarousel />
			</div>
		</div>
	);
};

export default React.memo(Homepage);
